@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;400;700&display=swap');
.rowdies-light {
  font-family: "Rowdies", sans-serif;
  font-weight: 300;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Dongle&family=Rowdies:wght@300;400;700&display=swap');
.dongle-regular {
  font-family: "Dongle", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.App {
  text-align: center;
}
*{
  scroll-behavior: smooth;
}
::-webkit-scrollbar-button{
  height: 4px !important;
  width: 4px !important;
}
::-webkit-scrollbar-thumb{
  height: 2px !important;
  width: 1px !important;
  border-radius: 10px;
  background-color: rgb(40, 197, 197);

}
::-webkit-scrollbar-track-piece{
  height: 4px !important;;
  background-color: white;
}

::-webkit-scrollbar{
  height: 5px !important;
  width: 7px !important;
  background-color: teal;
  cursor: pointer;
}
/* ::-webkit-scrollbar{
  height: 0;
} */
